import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import { TopbarContainer } from '..';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  ConfirmationModal,
  // ExtendBookingModal,
  ItemTable,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  PrimaryButton,
  NamedLink,
  Modal,
  BookingBreakdown,
} from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import {
  fetchPendingTransactions,
  fetchUpcomingTransactions,
  fetchPastTransactions,
  extendBookingPeriod,
  manualLoadData,
  contactLender,
  cancelTransaction,
} from './MyBorrowedItemsPage.duck';
import { calculateQuantityFromHours } from '../../util/dates';
import { formatMoney } from '../../util/currency';
import { formatDateForWantedPosts } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import { txState } from '../InboxPage/InboxPage';
import MenuIcon from '../../components/ManageListingCard/MenuIcon';
import { createSlug } from '../../util/urlHelpers';
import { SendMessageForm } from '../../forms';
import { parseTxToSharetribe } from '../../util/borrowedApiParser';
import config from '../../config';

import css from './MyBorrowedItemsPage.module.css';
import {
  txHasBeenDelivered,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
} from '../../util/transaction';

const { Money } = sdkTypes;

const MODALS = {
  CONTACT: 'contact',
  CANCEL: 'cancel',
  RECEIPT: 'receipt',
};

const TODAY = new Date();

const canShowReviewButton = (lastTransition, transitions) => {
  const tx = { attributes: { lastTransition, transitions } };

  const hasCompleted = txHasBeenDelivered(tx);
  const canLeaveReview = txIsDelivered(tx);
  const reviewPeriodIsOver = txIsReviewed(tx);

  const userHasLeftAReview = txIsInFirstReviewBy(tx, true);

  return canLeaveReview || (hasCompleted && !reviewPeriodIsOver && !userHasLeftAReview);
};

const MyBorrowedItemsPageComponent = props => {
  const {
    isAuthenticated,
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    currentUser,
    currentUserExpiringBookings,
    onLoadData,

    upcomingTransactions,
    upcomingTransactionsPagination,
    fetchUpcomingTransactionsInProgress,
    fetchUpcomingTransactionsError,
    onFetchUpcomingTransactions,

    pendingTransactions,
    pendingTransactionsPagination,
    fetchPendingTransactionsInProgress,
    fetchPendingTransactionsError,
    onFetchPendingTransactions,

    pastTransactions,
    pastTransactionsPagination,
    fetchPastTransactionsInProgress,
    fetchPastTransactionsError,
    onFetchPastTransactions,

    onContactLender,
    contactLenderInProgress,
    contactLenderError,

    onCancelTransaction,
    cancelTransactionInProgress,
    cancelTransactionError,
  } = props;

  const [modalOpen, setModalOpen] = useState();
  const [contactLenderInfo, setContactLenderInfo] = useState();
  const [selectedTx, setSelectedTx] = useState();

  const isContactLenderModalOpen = useMemo(() => modalOpen === MODALS.CONTACT, [modalOpen]);
  const isCancelModalOpen = useMemo(() => modalOpen === MODALS.CANCEL, [modalOpen]);
  const isReceiptModalOpen = useMemo(() => modalOpen === MODALS.RECEIPT, [modalOpen]);

  const isCurrentUserDeletedOrBanned = useMemo(
    () => currentUser && (currentUser.attributes.deleted || currentUser.attributes.banned),
    [currentUser]
  );

  const showSendMessageForm = !isCurrentUserDeletedOrBanned;

  const contactLenderMessages = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'MyBorrowedItemsPage.contactLenderTitle' }),
      placeholder: intl.formatMessage(
        { id: 'MyBorrowedItemsPage.contactLenderPlaceholder' },
        { name: contactLenderInfo ? contactLenderInfo.name : null }
      ),
      notAllowed: intl.formatMessage({ id: 'MyBorrowedItemsPage.contactLenderNotAllowed' }),
    }),
    [intl, contactLenderInfo]
  );

  const tableHeaders = useMemo(
    () => [
      { key: 'item-name', value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemName' }) },
      { key: 'item-lender', value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemLender' }) },
      {
        key: 'item-quantity',
        value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemQuantity' }),
      },
      {
        key: 'item-datetime',
        value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemDateTime' }),
      },
      { key: 'item-cost', value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemCost' }) },
      { key: 'item-actions', value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemActions' }) },
    ],
    [intl]
  );

  const tableHeadersWithStatus = useMemo(() => {
    const tableHeadersCopy = [...tableHeaders];

    tableHeadersCopy.splice(5, 0, {
      key: 'item-status',
      value: intl.formatMessage({ id: 'MyBorrowedItemsPage.itemStatus' }),
    });

    return tableHeadersCopy;
  }, [intl, tableHeaders]);

  const handleModalOpen = useCallback((type, resourse) => {
    switch (type) {
      case MODALS.CONTACT:
        setContactLenderInfo(resourse);
        break;

      case MODALS.CANCEL:
      case MODALS.RECEIPT:
        setSelectedTx(resourse);
        break;

      default:
        break;
    }

    setModalOpen(type);
  }, []);

  const handleModalClose = useCallback(type => {
    switch (type) {
      case MODALS.CONTACT:
        setContactLenderInfo(null);
        break;

      case MODALS.CANCEL:
      case MODALS.RECEIPT:
        setSelectedTx(null);
        break;

      default:
        break;
    }

    setModalOpen(null);
  }, []);

  const formatTransactionsForTable = useCallback(
    (transactions, type) => {
      const isPastTx = type === 'past';
      const isUpcomingTx = type === 'upcoming';

      const deletedListing = intl.formatMessage({ id: 'MyBorrowedItemsPage.deletedListing' });
      const deletedUser = intl.formatMessage({ id: 'MyBorrowedItemsPage.deletedUser' });

      return transactions.map(tx => {
        const {
          lastTransition,
          transitions,
          listing,
          seller,
          payinTotal,
          booking,
          sharetribeId: txId,
        } = tx;

        const { title, sharetribeId: listingId } = listing || {};
        const { firstName, lastName, role, sharetribeId: lenderId } = seller || {};
        const { seats, start, end } = booking || {};

        const isBusinessLender = role === 'Business';
        const lenderName = isBusinessLender ? firstName : `${firstName} ${lastName[0]}.`;

        const statusMaybe = isPastTx
          ? {
              itemStatus: txState(intl, { attributes: { lastTransition, transitions } }, 'order')
                ?.state,
            }
          : {};

        const payinAsMoney = payinTotal ? new Money(payinTotal.amount, payinTotal.currency) : null;
        const formattedMoney = payinAsMoney ? formatMoney(intl, payinAsMoney) : null;

        const startAsDate = start ? new Date(start) : null;
        const endAsDate = end ? new Date(end) : null;

        const startDate = startAsDate ? formatDateForWantedPosts(intl, startAsDate) : null;
        const endDate = endAsDate ? formatDateForWantedPosts(intl, endAsDate) : null;

        const duration =
          startAsDate && endAsDate ? calculateQuantityFromHours(startAsDate, endAsDate) : 0;

        const isHourly = duration < 24;

        const canCancel =
          !isPastTx && isHourly
            ? startAsDate && calculateQuantityFromHours(TODAY, startAsDate) > 1
            : startAsDate && calculateQuantityFromHours(TODAY, startAsDate) > 24;

        return {
          key: txId,
          value: {
            itemName: (
              <div className={css.itemName}>
                {listingId ? (
                  <NamedLink name="ListingPage" params={{ id: listingId, slug: createSlug(title) }}>
                    {title}
                  </NamedLink>
                ) : (
                  deletedListing
                )}
              </div>
            ),
            itemLender: lenderId ? (
              <NamedLink name="ProfilePage" params={{ id: lenderId }}>
                {lenderName}
              </NamedLink>
            ) : (
              deletedUser
            ),
            itemQuantity: seats,
            dateTime: isHourly ? (
              <div className={css.wantedPostDates}>
                <div className={css.itemTimeframe}>{startDate && startDate.date}</div>
                <div className={css.itemTimeframe}>
                  <span>
                    {startDate && startDate.time}
                    <span className={css.itemTimeframeSeparator}>-</span>
                  </span>
                  <span>{endDate && endDate.time}</span>
                </div>
              </div>
            ) : (
              <div className={css.itemTimeframe}>
                <span>
                  {startDate && startDate.date}
                  <span className={css.itemTimeframeSeparator}>-</span>
                </span>
                <span>{endDate && endDate.date}</span>
              </div>
            ),
            itemCost: formattedMoney,
            ...statusMaybe,
            itemActions: (
              <Menu contentPosition="left" useArrow={false}>
                <MenuLabel className={css.menuLabel}>
                  <MenuIcon />
                </MenuLabel>
                <MenuContent>
                  <MenuItem key="view-reservation">
                    <NamedLink
                      name="OrderDetailsPage"
                      params={{ id: txId }}
                      className={css.menuItemAsButton}
                    >
                      {intl.formatMessage({ id: 'MyBorrowedItemsPage.viewReservation' })}
                    </NamedLink>
                  </MenuItem>
                  {isUpcomingTx && (
                    <MenuItem key="contact-lender">
                      <PrimaryButton
                        className={css.menuItem}
                        onClick={() =>
                          handleModalOpen(MODALS.CONTACT, { id: txId, name: lenderName })
                        }
                      >
                        {intl.formatMessage({ id: 'MyBorrowedItemsPage.contactLender' })}
                      </PrimaryButton>
                    </MenuItem>
                  )}
                  {canCancel && (
                    <MenuItem key="cancel-tx">
                      <PrimaryButton
                        className={css.menuItem}
                        onClick={() => {
                          handleModalOpen(MODALS.CANCEL, {
                            id: txId,
                            isUpcomingTx,
                          });
                        }}
                      >
                        {intl.formatMessage({ id: 'MyBorrowedItemsPage.cancel' })}
                      </PrimaryButton>
                    </MenuItem>
                  )}
                  {isPastTx && canShowReviewButton(lastTransition, transitions) && (
                    <MenuItem key="leave-review">
                      <NamedLink className={css.menuItem} name="ReviewPage" params={{ id: txId }}>
                        {intl.formatMessage({ id: 'MyBorrowedItemsPage.leaveReview' })}
                      </NamedLink>
                    </MenuItem>
                  )}
                  {isPastTx && (
                    <MenuItem key="view-receipt">
                      <PrimaryButton
                        className={css.menuItem}
                        onClick={() => {
                          handleModalOpen(MODALS.RECEIPT, parseTxToSharetribe(tx));
                        }}
                      >
                        {intl.formatMessage({ id: 'MyBorrowedItemsPage.viewReceipt' })}
                      </PrimaryButton>
                    </MenuItem>
                  )}
                </MenuContent>
              </Menu>
            ),
          },
        };
      });
    },
    [handleModalOpen, intl]
  );

  const upcomingItems = useMemo(
    () => formatTransactionsForTable(upcomingTransactions, 'upcoming'),
    [formatTransactionsForTable, upcomingTransactions]
  );
  const pendingItems = useMemo(() => formatTransactionsForTable(pendingTransactions, 'pending'), [
    formatTransactionsForTable,
    pendingTransactions,
  ]);
  const pastItems = useMemo(() => formatTransactionsForTable(pastTransactions, 'past'), [
    formatTransactionsForTable,
    pastTransactions,
  ]);

  const handleContactLender = useCallback(
    (values, form) => {
      const message = values.message ? values.message.trim() : null;

      if (!message || !contactLenderInfo) {
        return;
      }

      onContactLender(contactLenderInfo.id, message)
        .then(() => {
          form.reset();
          handleModalClose(MODALS.CONTACT);
        })
        .catch(e => {
          // Ignore, Redux handles the error
        });
    },
    [contactLenderInfo, handleModalClose, onContactLender]
  );

  // const onExtendBooking = useCallback(values => {
  //   const { transactionIdToExtend, onExtendBooking } = this.props;
  //   const { hours } = values;

  //   onExtendBooking(transactionIdToExtend, hours);
  // }, []);

  const handleCancelTransaction = useCallback(() => {
    if (!selectedTx) return;

    onCancelTransaction(selectedTx.id, selectedTx.isUpcomingTx)
      .then(() => {
        handleModalClose(MODALS.CANCEL);
      })
      .catch(() => {});
  }, [handleModalClose, onCancelTransaction, selectedTx]);

  const handleFetchUpcomingTransactions = useCallback(
    page => {
      if (currentUser && currentUser.id && currentUser.id.uuid)
        onFetchUpcomingTransactions(currentUser.id.uuid, page);
    },
    [currentUser, onFetchUpcomingTransactions]
  );

  const handleFetchPendingTransactions = useCallback(
    page => {
      if (currentUser && currentUser.id && currentUser.id.uuid)
        onFetchPendingTransactions(currentUser.id.uuid, page);
    },
    [currentUser, onFetchPendingTransactions]
  );

  const handleFetchPastTransactions = useCallback(
    page => {
      if (currentUser && currentUser.id && currentUser.id.uuid)
        onFetchPastTransactions(currentUser.id.uuid, page);
    },
    [currentUser, onFetchPastTransactions]
  );

  useEffect(() => {
    if (currentUser && currentUser.id && currentUser.id.uuid) onLoadData(currentUser.id.uuid);
  }, [currentUser, onLoadData]);

  return (
    <Page
      title={intl.formatMessage({ id: 'MyBorrowedItemsPage.pageName' })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MyBorrowedItemsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <h1 className={css.title}>{intl.formatMessage({ id: 'MyBorrowedItemsPage.title' })}</h1>
            <h2 className={css.tableTitle}>
              {intl.formatMessage({ id: 'MyBorrowedItemsPage.upcomingTitle' })}
            </h2>
            {currentUserExpiringBookings?.length ? (
              <p>
                {/* {intl.formatMessage(
                  { id: 'MyBorrowedItemsPage.expiringInfo' },
                  { count: currentUserExpiringBookings.length }
                )} */}
              </p>
            ) : null}
            <ItemTable
              tableHeaders={tableHeaders}
              fetchInProgress={fetchUpcomingTransactionsInProgress}
              fetchError={fetchUpcomingTransactionsError}
              itemActionInProgress="aaa"
              itemActionError="aaa"
              items={upcomingItems}
              noItemsMessage={intl.formatMessage(
                { id: 'MyBorrowedItemsPage.upcomingNoItems' },
                {
                  learnMoreLink: (
                    <NamedLink key="learn-more-page-link" name="LearnMoreBasePage">
                      {intl.formatMessage({ id: 'MyBorrowedItemsPage.learnMore' })}
                    </NamedLink>
                  ),
                }
              )}
              usePagination
              pagination={upcomingTransactionsPagination}
              onFetchPage={handleFetchUpcomingTransactions}
            />
            <h2 className={css.tableTitle}>
              {intl.formatMessage({ id: 'MyBorrowedItemsPage.pendingTitle' })}
            </h2>

            <ItemTable
              tableHeaders={tableHeaders}
              fetchInProgress={fetchPendingTransactionsInProgress}
              fetchError={fetchPendingTransactionsError}
              itemActionInProgress="aaa"
              itemActionError="aaa"
              items={pendingItems}
              noItemsMessage={intl.formatMessage({ id: 'MyBorrowedItemsPage.pendingNoItems' })}
              usePagination
              pagination={pendingTransactionsPagination}
              onFetchPage={handleFetchPendingTransactions}
            />
            <h2 className={css.tableTitle}>
              {intl.formatMessage({ id: 'MyBorrowedItemsPage.pastTitle' })}
            </h2>

            <ItemTable
              tableHeaders={tableHeadersWithStatus}
              fetchInProgress={fetchPastTransactionsInProgress}
              fetchError={fetchPastTransactionsError}
              itemActionInProgress="aaa"
              itemActionError="aaa"
              items={pastItems}
              noItemsMessage={intl.formatMessage({ id: 'MyBorrowedItemsPage.pastNoItems' })}
              usePagination
              pagination={pastTransactionsPagination}
              onFetchPage={handleFetchPastTransactions}
            />
            <div>
              <NamedLink
                name="SearchPage"
                to={{
                  search: `address=Seattle%2C%20Washington%2C%20United%20States&bounds=47.7788030383864%2C-122.218864003193%2C47.3506859580852%2C-122.435900673909`,
                }}
                className={css.button}
              >
                {intl.formatMessage({ id: 'MyBorrowedItemsPage.exploreBorrowed' })}
              </NamedLink>
            </div>
            <div>
              <NamedLink name="HelpPage">
                {intl.formatMessage({ id: 'MyBorrowedItemsPage.helpCenter' })}
              </NamedLink>
            </div>
          </div>
          {/*
            <ExtendBookingModal
              id="ExtendBookingModal"
              isOpen={isExtendModalOpen}
              onCloseModal={onCloseExtendModal}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmitExtendBooking={this.onExtendBooking}
              bookingName={'bookingName'}
              transaction={activeTransactions.find(t => t.sharetribeId === transactionIdToExtend)}
              extendBookingSent={sendExtendSubmitted}
              extendBookingError={sendExtendError}
              extendBookingInProgress={sendExtendInProgress}
            />*/}
          <ConfirmationModal
            id="MyBorrowedItemsPage.cancelModal"
            onManageDisableScrolling={onManageDisableScrolling}
            isOpen={isCancelModalOpen}
            onCloseModal={() => handleModalClose(MODALS.CANCEL)}
            onSubmit={handleCancelTransaction}
            modalTitle={intl.formatMessage({ id: 'MyBorrowedItemsPage.cancelModalTitle' })}
            modalButtonText={intl.formatMessage({ id: 'MyBorrowedItemsPage.yes' })}
            inProgress={cancelTransactionInProgress}
            error={
              cancelTransactionError ? (
                <p className={css.error}>
                  {intl.formatMessage({ id: 'MyBorrowedItemsPage.cancelError' })}
                </p>
              ) : null
            }
          />

          <Modal
            id="MyBorrowedItemsPage.contactLender"
            isOpen={isContactLenderModalOpen}
            onClose={() => handleModalClose(MODALS.CONTACT)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
            contentClassName={css.modalContent}
          >
            {showSendMessageForm && <h2>{contactLenderMessages.title}</h2>}
            {showSendMessageForm ? (
              <SendMessageForm
                formId="MyBorrowedItemsPage.SendMessageForm"
                messagePlaceholder={contactLenderMessages.placeholder}
                inProgress={contactLenderInProgress}
                sendMessageError={contactLenderError}
                onSubmit={handleContactLender}
                className={css.sendMessageForm}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{contactLenderMessages.notAllowed}</div>
            )}
          </Modal>
          <Modal
            id="MyBorrowedItemsPage.receiptModal"
            isOpen={isReceiptModalOpen}
            onClose={() => handleModalClose(MODALS.RECEIPT)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
            contentClassName={css.modalContent}
          >
            <p className={css.modalCompanyName}>
              {intl.formatMessage({ id: 'MyBorrowedItemsPage.borrowedInc' })}
              {selectedTx?.id?.uuid && (
                <p>
                  <b>{intl.formatMessage({ id: 'MyBorrowedItemsPage.bookingId' })}</b>
                  {selectedTx?.id?.uuid}
                </p>
              )}
            </p>

            {selectedTx && (
              <h2 className={css.bookingBreakdownTitle}>
                {txHasBeenDelivered(selectedTx)
                  ? intl.formatMessage({ id: 'MyBorrowedItemsPage.bookingReceipt' })
                  : intl.formatMessage({ id: 'MyBorrowedItemsPage.bookingEstimate' })}
              </h2>
            )}

            {isReceiptModalOpen && (
              <BookingBreakdown
                userRole="customer"
                unitType={config.bookingUnitType}
                transaction={selectedTx}
                booking={selectedTx?.booking}
                listingTitle={selectedTx?.listing?.attributes?.title}
              />
            )}
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <LandingFooter isAuthenticated={isAuthenticated} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

MyBorrowedItemsPageComponent.defaultProps = {};

MyBorrowedItemsPageComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  const {
    upcomingTransactions,
    upcomingTransactionsPagination,
    fetchUpcomingTransactionsInProgress,
    fetchUpcomingTransactionsError,

    pendingTransactions,
    pendingTransactionsPagination,
    fetchPendingTransactionsInProgress,
    fetchPendingTransactionsError,

    pastTransactions,
    pastTransactionsPagination,
    fetchPastTransactionsInProgress,
    fetchPastTransactionsError,

    contactLenderInProgress,
    contactLenderError,

    cancelTransactionInProgress,
    cancelTransactionError,
  } = state.MyBorrowedItemsPage;

  const { currentUser, currentUserExpiringBookings } = state.user;

  return {
    isAuthenticated,

    upcomingTransactions,
    upcomingTransactionsPagination,
    fetchUpcomingTransactionsInProgress,
    fetchUpcomingTransactionsError,

    pendingTransactions,
    pendingTransactionsPagination,
    fetchPendingTransactionsInProgress,
    fetchPendingTransactionsError,

    pastTransactions,
    pastTransactionsPagination,
    fetchPastTransactionsInProgress,
    fetchPastTransactionsError,

    contactLenderInProgress,
    contactLenderError,

    cancelTransactionInProgress,
    cancelTransactionError,

    currentUser,
    currentUserExpiringBookings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),

  onFetchPendingTransactions: (currentUserId, page) =>
    dispatch(fetchPendingTransactions(currentUserId, page)),
  onFetchUpcomingTransactions: (currentUserId, page) =>
    dispatch(fetchUpcomingTransactions(currentUserId, page)),
  onFetchPastTransactions: (currentUserId, page) =>
    dispatch(fetchPastTransactions(currentUserId, page)),

  onExtendBooking: (transactionId, hours) => dispatch(extendBookingPeriod(transactionId, hours)),
  onContactLender: (txId, message) => dispatch(contactLender(txId, message)),
  onCancelTransaction: (txId, isUpcomingTx) => dispatch(cancelTransaction(txId, isUpcomingTx)),

  onLoadData: userId => dispatch(manualLoadData(userId)),
});

const MyBorrowedItemsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MyBorrowedItemsPageComponent);

export default MyBorrowedItemsPage;
