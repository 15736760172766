import { convertToDecimal } from './currency';
import { types as sdkTypes } from './sdkLoader';
import { ensureBooking, ensureListing, ensureTransaction, ensureUser } from './data';

const { UUID, Money, LatLng } = sdkTypes;

export const parseUserToSharetribe = user => {
  if (!user) return ensureUser({});

  const { sharetribeId, firstName, lastName, banned, deleted, username, ...rest } = user;

  const abbreviatedName = firstName[0].toUpperCase() + lastName[0].toUpperCase();

  const userToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'user',
    attributes: {
      banned: !!banned,
      deleted: !!deleted,
      profile: {
        displayName: username || `${firstName} ${lastName[0]}`,
        abbreviatedName,
      },
    },
  };

  return ensureUser(userToReturn);
};

export const parseListingToSharetribe = (listing, user) => {
  if (!listing) return ensureListing({});

  const {
    sharetribeId,
    title,
    description,
    deleted,
    state,
    price,
    publicData,
    geolocation,
    availabilityPlan,
    image,
    ...rest
  } = listing;

  const listingToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'listing',
    attributes: {
      title,
      description,
      deleted,
      state,
      price: price ? new Money(price.amount, price.currency) : null,
      publicData,
      geolocation: geolocation ? new LatLng(geolocation.lat, geolocation.lng) : null,
      availabilityPlan,
    },
    author: parseUserToSharetribe(user),
  };

  return ensureListing(listingToReturn);
};

export const parseBookingToSharetribe = booking => {
  if (!booking) return ensureBooking({});

  const { sharetribeId, start, end, displayStart, displayEnd, state, seats, ...rest } = booking;

  const bookingToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'booking',
    attributes: {
      start: new Date(start),
      end: new Date(end),
      displayStart: new Date(displayStart),
      displayEnd: new Date(displayEnd),
      state,
      seats,
    },
  };

  return ensureBooking(bookingToReturn);
};

const parseLineItemsToSharetribe = lineItems =>
  lineItems.map(({ lineTotal, unitPrice, quantity, ...rest }) => ({
    ...rest,
    lineTotal: lineTotal ? new Money(lineTotal.amount, lineTotal.currency) : null,
    unitPrice: unitPrice ? new Money(unitPrice.amount, unitPrice.currency) : null,
    quantity: quantity ? convertToDecimal(quantity.value) : null,
  }));

const parseTransitionsToSharetribe = transitions =>
  transitions.map(t => ({ ...t, createdAt: new Date(t.createdAt) }));

export const parseTxToSharetribe = tx => {
  if (!tx) return ensureTransaction({});

  const {
    lastTransition,
    lastTransitionedAt,
    transitions,
    listing,
    seller,
    buyer,
    payinTotal,
    payoutTotal,
    lineItems,
    booking,
    createdAt,
    sharetribeId,
    ...rest
  } = tx;

  const txToReturn = {
    ...rest,
    id: new UUID(sharetribeId),
    type: 'transaction',
    attributes: {
      lastTransition,
      lastTransitionedAt: new Date(lastTransitionedAt),
      createdAt: new Date(createdAt),
      payinTotal: payinTotal ? new Money(payinTotal.amount, payinTotal.currency) : null,
      payoutTotal: payoutTotal ? new Money(payoutTotal.amount, payoutTotal.currency) : null,
      lineItems: lineItems ? parseLineItemsToSharetribe(lineItems) : [],
      transitions: parseTransitionsToSharetribe(transitions),
    },
    listing: parseListingToSharetribe(listing, seller),
    booking: parseBookingToSharetribe(booking),
    customer: parseUserToSharetribe(buyer),
    provider: parseUserToSharetribe(seller),
  };

  return ensureTransaction(txToReturn);
};
